

export const MeowPrivacy = () => {


    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%', height: '100%', padding: 50,}}>
            <h1>Meow Tuner Privacy Policy</h1>

<br/>
<p>We don't track or save or want any of your data.</p>


        </div>
    )

}