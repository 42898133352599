import React from 'react';
import endshipLogo from './assets/images/endship.png';
import meowTunerGif from './assets/images/meowtuner.gif';
import impressionIdolLogo from './assets/images/impressionidol.png';
import accountabullLogo from './assets/images/accountabull-icon.png';
import whiteLogo from './assets/images/logo-white.png';
import './main.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIwxWuaPmA4iZewLzReOLeVaCfHUVBC1k",
  authDomain: "spotlightly-cb084.firebaseapp.com",
  projectId: "spotlightly-cb084",
  storageBucket: "spotlightly-cb084.appspot.com",
  messagingSenderId: "117429392175",
  appId: "1:117429392175:web:62ed19ed3eda0fe2e5c7da",
  measurementId: "G-1GV28LHP0C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);


function App() {

  const currentYear = new Date().getFullYear()
  const yearText = `© ${currentYear}`;



  return (
    <div>
    <div className='hero-container'>
    <header>
      <div className='logo-section'>
        <img src={whiteLogo} alt='spotlightly logo mark'/>
        <h1 style={{ display: 'none'}}>Spotlightly</h1>
      </div>
    </header>
    <div className='text-container'>
      <h2>We Do Apps for Adults</h2>
    </div>
  </div>
    <main className='apps-container' id='apps'>


    <div className='spacer'>&nbsp;</div>

    <div className='app endship right-image'>
      <section className='app-content'>
        <h2>Endship</h2>
        <div className='app-details'>
          <div className='links'>
            <div className='app-links'>
              <h4>App</h4>
              <a href='https://itunes.apple.com/us/app/endship/id1098049379?ls=1&mt=8'>
                <i className='fa fa-2x fa-apple'></i>
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.endship.spotlightly'>
                <i className='fa fa-2x fa-android'></i>
              </a>
            </div>
            <div className='community-links'>
              <h4>Web</h4>
              <a href='https://www.endship.com'>
                <i className='fa fa-2x fa-globe'></i>
              </a>
            </div>
            <div className='community-links'>
              <h4>Social</h4>
              <a href='http://www.instagram.com/endshipgame'>
                <i className='fa fa-2x fa-instagram'></i>
              </a>
              <a href='http://www.twitter.com/endshipgame'>
                <i className='fa fa-2x fa-twitter'></i>
              </a>
            </div>
          </div>
          <div className='blurb'>
            <p>Endship is a game to roast your friends</p>
          </div>
        </div>
      </section>
      <section className='app-image'>
        <div className='image-container'>
          <img src={endshipLogo} alt="Endship logo"/>
        </div>
      </section>
    </div>

    <div className='app meow left-image'>
      <section className='app-image'>
        <div className='image-container'>
          <img src={meowTunerGif} className="meow-image" alt="Meow Tuner logo" />
        </div>
      </section>
      <section className='app-content'>
        <h2>Meow Tuner</h2>
        <div className='app-details'>
          <div className='links'>
            <div className='app-links'>
              <h4>App</h4>
              <a href='https://apps.apple.com/us/app/meow-tuner/id1315434056' className='button'>
                <i className='fa fa-2x fa-apple'></i>
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.meowtunerapp&hl=en_CA'>
                <i className='fa fa-2x fa-android'></i>
              </a>
            </div>
          </div>
          <div className='blurb'>
            <p>Meow Tuner is a guitar tuner that plays out of tune cat noises</p>
          </div>
        </div>
      </section>
    </div>

    <div className='app imp right-image'>
      <section className='app-content'>
        <h2>Impression Idol</h2>
        <div className='app-details'>
          <div className='links'>
            <div className='app-links'>
              <h4>App</h4>
              <a href='https://itunes.apple.com/us/app/impression-idol/id1315482624' className='button'>
                <i className='fa fa-2x fa-apple'></i>
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.impressionidol'>
                <i className='fa fa-2x fa-android'></i>
              </a>
            </div>
            <div className='community-links'>
              <h4>Web</h4>
              <a href='https://www.impressionidol.com'>
                <i className='fa fa-2x fa-globe'></i>
              </a>
            </div>
          </div>
          <div className='blurb'>
            <p>Impression Idol is a party game that requires players to be good at impressions for it to be fun. </p>
          </div>
        </div>
      </section>
      <section className='app-image'>
        <div className='image-container'>
          <img src={impressionIdolLogo} alt="Impression Idol logo" />
        </div>
      </section>
    </div>

    <div className='app meow left-image'>
      <section className='app-image'>
        <div className='image-container'>
          <img src={accountabullLogo} className="abby-image" alt="Accountabull logo" />
        </div>
      </section>
      <section className='app-content'>
        <h2>Accountabull</h2>
        <div className='app-details'>
          <div className='links'>
            <div className='app-links'>
              <h4>Web</h4>
              <a href='https://www.accountabull.com' className='button'>
                <i className='fa fa-2x fa-globe'></i>
              </a>
            </div>
          </div>
          <div className='blurb'><p>Coming soon to app stores near you</p></div>
        </div>
      </section>
    </div>



  </main>

  <footer id='hi'>
    <div className='footer-container'>
      <div className='left-side'>
        <div className='footer-logo'>
          <img src={whiteLogo} alt='spotlightly logo mark'/>
          <p>Made in <span>Canada</span>
          </p>
        </div>
      </div>
      <div className='right-side'>
        <aside className='social-links'>
          <a href='http://linkedin.com/company/spotlightly'><i className='fa fa-15x fa-linkedin'></i></a>
        </aside>
              <p>{yearText}</p>
      </div>
    </div>



</footer>

</div>
  );
}

export default App;
